
import axios, { AxiosError, AxiosResponse } from "axios";


const PRODUCAO = `https://portalcloud.grupojcr.com.br/api/portalgrupojcr-1.0/`;
const HOMOLOGACAO = `http://10.254.66.2:8081/portalgrupojcr-1.0/`;
const DESENV = `http://10.0.0.131:8081/`;

//10.0.2.54

const teste = -{

}


const axiosInstance = axios.create({
  baseURL: `${PRODUCAO}`,
  timeout: 0xFFFFFFFF,

  headers: {
    Accepted: 'appication/json',

    'Content-Type': 'application/json',
  },
});


axiosInstance.interceptors.response.use(undefined, error => {
  if (error.response) {
    const { status, data, config } = error.response;

    switch (status) {
      case 400:
        console.log(status);
        break;
      case 401:
        console.log(status);
        break;
      case 404:
        console.log(status);
        break;
      case 500:
        console.log("Erro 500 validar front-end e back-end possivel mudança de SQL, ou alguns dos parametros está invalido");
        break;
      case 200:
        console.log(status);
        break;
      case 201:
        console.log(status);
        break;
      default:
        console.log(status);
        break;
    }
  }

  return Promise.reject(error);
});

axiosInstance.interceptors.request.use(
  (config) => {
    const storageData = JSON.parse(localStorage.getItem('jwttoken'));
    const jwttoken = storageData.jwttoken;
    if (jwttoken) {
      config.headers['Authorization'] = 'Bearer ' + jwttoken;
    }
    return config;
  },

);

export default axiosInstance;