import { TableBody, TableCell, IconButton, TableHead, TableRow, Tooltip, TablePagination, Dialog, Stack, Checkbox, Modal, Divider } from '@mui/material';
import { Card } from '@material-ui/core';
import Builhorn from '../../common/template/img/bullhorn.png';
import SefazIcon from '../../common/template/img/Sefaz/ConfirmacaoOperacaoIcon.JPG';
import CienciaDaOperacaoIcon from '../../common/template/img/Sefaz/CienciaDaOperacaoIcon.JPG';
import DenagadaIcon from '../../common/template/img/Sefaz/DenegadaIcon.jpg';
import WarningIcon from '../../common/template/img/Warning.png';
import Success from '../../common/template/img/success.png'
import CloseButtonIcon from '../../common/template/img/closebutton.png';
import SefazStatusEdit from '../../common/template/img/Sefaz/AutorizadoUsoIcon.JPG';
import InfoIcon from '../../common/template/img/info.png'

import qs from 'qs';

import imagemNfe from '../../common/template/img/sefaz.png'
import imagemLogoJCR from '../../common/template/img/logo-jcr.png'
import imagemLog from '../../common/template/img/log-trade.png'
import imagemFR4 from '../../common/template/img/FR4.png'


import React from 'react';
import Footer from '../../common/template/footer';
import Header from '../../common/template/header';
import SideBar from '../../common/template/sideBar';
import Table from '@mui/material/Table';
import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import axiosInstance from '../../config/api';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Spinner from '../../common/template/Spinner';
import EbpsIcon from '../../common/template/img/EBPS.png'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import moment from 'moment';

import './nferecolhercard.css';
import './nfselayoutscroll.css';
import './personalizadocampos.css';

export default function NfeDocumentoEntrada(props) {
    let countCiencia = 0;
    let countConcluida = 0;
    let countForaPrazo = 0;

    const estiloImagem = {
        borderRadius: '50%',
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '52%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: '#ffffff',
        border: '2px solid #ffffff',
        boxShadow: 10,
        pt: 2,
        px: 4,
        pb: 3,
    };
    const today = new Date();
    const [totalElements, setTotalElements] = React.useState(0);
    const [totalElementsNfeResumo, setTotalElementsResumo] = React.useState(0);
    const [quantidadenfecount, setquantidadenfecount] = React.useState([]);
    const [quantidadenfecountResumo, setquantidadenfecountResumo] = React.useState([]);

    const [DisabledNextButton, setDisabledNextButton] = React.useState(false);
    const [DisabledNextButtonResumo, setDisabledNextButtonResumo] = React.useState(false);

    const [Coligada, setColigada] = React.useState([]);
    const [alertConcluido, setAlertConcluido] = React.useState(false);
    const [SituacaoNfeAuxDefault, setSituacaoNfeAuxDefault] = React.useState(false);
    const [expanded, setExpanded] = React.useState(true);
    const [AlertaManifestacao, setAlertaManifestacao] = React.useState(false);
    const [AlertaManifestacaoAux, setAlertaManifestacaoAux] = React.useState(false);
    const [nfe, setNfe] = React.useState([]);
    const [nfeaux, setNfeAux] = React.useState([]);
    const [SituacaoNfeAux, setSituacaoNfeAux] = React.useState(false);
    const [pagenumber, setpageNumber] = React.useState('');
    const [totalPaginas, setTotalpages] = React.useState('')
    const [popStatusManifestacao, setPopStatusManifestacao] = React.useState();
    const [loading, setloading] = React.useState(false);
    const [quantidade, setQuantidadenfe] = React.useState([]);
    const [quantidadeJCR, setQuantidadenfeJCR] = React.useState([]);
    const [quantidadeEBPS, setQuantidadenfeEBPS] = React.useState([]);
    const [quantidadeSEAGULL, setQuantidadenfeSEAGULL] = React.useState([]);
    const [quantidadeLOG, setQuantidadenfLOG] = React.useState([]);
    const [quantidadeFR4, setQuantidadenfFR4] = React.useState([]);
    const [completed, setcompleted] = React.useState(false);
    const [Chave, setChave] = React.useState('');
    const [Datainicial, setDatainicial] = React.useState('');
    const [Datafinal, setDatafinal] = React.useState('');
    const [DatainicialAux, setDatainicialAux] = React.useState('');
    const [DatafinalAux, setDatafinalAux] = React.useState('');
    const [ComboColigadasFornecedor, setComboColigadasFornecedor] = React.useState([]);
    const [ComboColigadasFornecedorAux, setComboColigadasFornecedorAux] = React.useState([]);
    const [SituacaoNfe, setSituacaoNfe] = React.useState(false);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [selectedCNPJ, setSelectedCNPJ] = React.useState(false);
    const [numSelected, setNumSelected] = React.useState(0);
    const [message, setMessage] = React.useState("");
    const [disableManifestButton, setDisableManifestButton] = React.useState(false);
    const isDeleteButtonDisabled = selectedRows.length === 0;
    const [selectedOption, setSelectedOption] = React.useState('');
    const [cnpjdestino, setcnpjdestino] = React.useState('');
    const [cnpjdestinoaux, setcnpjdestinoaux] = React.useState('');
    const [StatusManifestacaoAutorizacaoUso, setStatusManifestacaoAutorizacaoUso] = React.useState('');
    const [StatusManifestacaoCienciaOperacao, setStatusManifestacaoCienciaOperacao] = React.useState('');
    const [StatusManifestacaoCancelada, setStatusManifestacaoCancelada] = React.useState('');
    const [StatusManifestacaoConfirmacaoOperacao, setStatusManifestacaoConfirmacaoOperacao] = React.useState('');
    const [alertWarning, setalertWarning] = React.useState(false);
    const [ChaveAux, setChaveAux] = React.useState('');
    const [ColigadaAux, setColigadaAux] = React.useState('');

    const [totalautorizacaodeuso, settotalautorizacaodeuso] = React.useState([]);
    const [totalciencia, settotalciencia] = React.useState([]);
    const [totalnaorealizada, settotalnaorealizada] = React.useState([]);
    const [totalconfirmacao, settotalconfirmacao] = React.useState([]);
    const [totaldesconhecimentooperacao, settotaldesconhecimentooperacao] = React.useState([]);

    const [alertConcluidoAlerta, setalertConcluidoAlerta] = React.useState(false);


    const handleCloseWarning = () => {
        setalertWarning(false);
    }

    const handleOpenWarning = () => {
        setalertWarning(true);
    }


    const handleCloseManifesto = () => {
        setAlertConcluido(false);
    };

    const handleCloseManifestoAlert = () => {
        setalertConcluidoAlerta(false);
    }

    const handleClose = () => {
        setAlertaManifestacao(false);
    };
    const handleCloseAux = () => {
        setAlertaManifestacaoAux(false);
    };
    const handleExpand = () => {
        setExpanded(!expanded);
    };
    const gerarXML = async (chave) => {
        const response = await axiosInstance.get(`/api/v1/notaentradaprodutosefaz/gerarXML/` + chave, { responseType: 'xml;charset=UTF-8;' });

        if (response && response.data) {


            const file = new Blob([response.data.split('&').join('&amp;')], { type: 'text/xml;charset=UTF-8;', });



            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }
    }

    const gerarPdf = async (chave) => {
        const response = await axiosInstance.get(`/api/v1/notaentradaprodutosefaz/gerarpdf/` + chave, { responseType: 'blob' });

        if (response && response.data) {
            const file = new Blob([response.data], { type: 'application/pdf;charset=UTF-8', });
            const fileURL = URL.createObjectURL(file);

            window.open(fileURL);
        }

    }

    const consultaDetalhada = async (chave) => {
        window.location.href = "/documentoentradanfe/" + chave;
    }
    const handleChangeRowsPerPage = (event) => {
        setpageNumber(pagenumber++);
    };
    const handleChangePage = (event, newPage) => {
        setpageNumber(newPage);
    };
    const handleFecharCloseCrud = () => {
        setPopStatusManifestacao(false);
        refresh();
    };
    const handleManifestar = (event, id) => {

        // Check if the checkbox is checked
        if (event.target.checked) {
            // Add the id to the selected rows
            handleRowSelect(event, id);
            setPopStatusManifestacao(true);

        } else {
            // Remove the id from the selected rows
            const newSelectedRows = selectedRows.filter((rowId) => rowId !== id);

            setSelectedRows(newSelectedRows);
            setPopStatusManifestacao(true);


        }
    };
    const handleRowSelect = (event, id, empresadestino, cnpjdestino, dthemissao, situacaoenvio) => {
        const checkbox = event.target.closest("div").querySelector("input[type='checkbox']"); // pega a checkbox clicada
        if (!id || !checkbox) {
            return;
        }
        const selectedIndex = selectedRows.findIndex((row) => row.id === id);
        let newSelectedRows = [];
        if (selectedIndex === -1) {
            newSelectedRows = [...selectedRows, { id, empresadestino, cnpjdestino, dthemissao, situacaoenvio }];
        } else {
            newSelectedRows = selectedRows.filter((row) => row.id !== id);
        }
        const uniqueEmpresasDestino = [...new Set(newSelectedRows.map((row) => row.empresadestino))];

        const confirmacaoOperacaoSelected = newSelectedRows.every((row) => {
            return row.situacaoenvio === "Confirmação da Operação" ||
                row.situacaoenvio === "NF-e Cancelada" ||
                row.situacaoenvio === "Uso denegado";
        });

        const uniqueCnpj = newSelectedRows
            .map((row) => row.cnpjdestino) // pega somente o CNPJ destino
            .filter((cnpj) => cnpj) // remove os valores undefined
            .filter((cnpj, index, array) => array.indexOf(cnpj) === index); // remove os valores duplicados
        setcnpjdestinoaux(uniqueCnpj.join(", ")); // exibe a string com os CNPJs separados por vírgula

        // Verifica se há linhas selecionadas
        if (newSelectedRows.length > 0) {
            // Verifica se a data de emissão é mais antiga que 10 dias
            const emissaoMaisAntiga = newSelectedRows.reduce((prev, current) => {
                const emissao = new Date(current.dthemissao);
                if (prev === null || emissao < prev) {
                    return emissao;
                }
                return prev;
            }, null);
            const hoje = new Date();
            const diffEmDias = Math.ceil((hoje - emissaoMaisAntiga) / (1000 * 60 * 60 * 24));

        } else {
            setDisableManifestButton(false);
            setAlertaManifestacaoAux(false); // Limpa a mensagem de alerta
        }

        if (confirmacaoOperacaoSelected) {
            setDisableManifestButton(true);
        } else {
            setDisableManifestButton(false);
        }

        setSelectedRows(newSelectedRows);
        setMessage(`Você selecionou ${newSelectedRows.length} documento(s) para a manifestação do destinatário.`);
    };

    async function refresh() {
        setTimeout(() => {
            setloading(true);
            //window.location.href = '/';
        }, 1);
        window.location.href = "/documentoentradanfe"
    }

    async function handleManifestarAPI() {
        setalertConcluidoAlerta(true);
        setloading(true);
        if (selectedOption === 'CienciadaOperacao') {
            const selectedIds = selectedRows.map(row => row.id);
            const coligadaIds = selectedRows.map(row => row.cnpjdestino);



            const response = await axiosInstance.get(`/api/v1/notaentradaprodutosefaz/manifestarciencia/${selectedIds.join(',')}/${cnpjdestinoaux}`)
                .then(resp => {

                    setAlertConcluido(true);
                    setalertConcluidoAlerta(false);
                    refresh();
                }).catch(error => {
                    refresh();
                });


        } else if (selectedOption === 'ConfirmacaoOperacao') {
            const selectedIds = selectedRows.map(row => row.id);

            const response = await axiosInstance.get(`/api/v1/notaentradaprodutosefaz/manifestarconfirmacao/${cnpjdestinoaux}/${selectedIds.join(',')}`)
                .then(resp => {
                    setAlertConcluido(true);
                    setloading(false);
                    setalertConcluidoAlerta(false);
                    refresh();
                }).catch(error => {
                    refresh();
                });


        } else if (selectedOption === 'DesconhecidoOperacao') {

            const selectedIds = selectedRows.map(row => row.id);
            const response = await axiosInstance.get(`/api/v1/notaentradaprodutosefaz/manifestarOperacaoDesconhecimento/${cnpjdestinoaux}/${selectedIds.join(',')}`)
                .then(resp => {

                    setAlertConcluido(true);
                    setalertConcluidoAlerta(false);
                    refresh();

                }).catch(error => {

                    refresh();
                    setloading(false);
                    setalertConcluidoAlerta(false);
                });
        } else if (selectedOption === 'OperacaoNaorealizada') {
            const selectedIds = selectedRows.map(row => row.id);
            const response = await axiosInstance.get(`/api/v1/notaentradaprodutosefaz/manifestarnaorealizada/${selectedIds.join(',')}/${cnpjdestinoaux}`)
                .then(resp => {

                    setalertConcluidoAlerta(false);
                    refresh();
                }).catch(error => {
                    refresh();
                    setalertConcluidoAlerta(false);
                    setloading(false);
                });

        }
        setloading(false);
        setalertConcluidoAlerta(false);
    }



    function handleOptionChange(event) {
        setSelectedOption(event.target.value);

    }

    async function handleBuscarNfeCompleta() {
        let queryParams = {};

        if (ColigadaAux !== '') {
            queryParams.cnpj = ColigadaAux;
        }

        if (Chave !== '') {
            queryParams.chaveacesso = Chave.replace(/\s/g, '');
        }

        if (Datainicial !== '') {
            queryParams.datainicial = `${Datainicial}T00:00:00`;
        }

        if (Datafinal !== '') {
            queryParams.datafinal = `${Datafinal}T23:59:59`;
        }

        const queryString = new URLSearchParams(queryParams).toString();

        const response = await axiosInstance.get(`/api/v1/notaentradaprodutosefaz/paginado/?${queryString}`)

        setTotalElements(response.data.totalElements);

        console.log("DEBUGANDO O TOTAL DE ELEMENTOS" + response.data.totalElements)

        if (response.data.pageable.offset === 0) {
            setquantidadenfecount(response.data.pageable.pageSize);
        } else {
            setquantidadenfecount(response.data.pageable.offset);
        }

        if (response.data.content.length === 0) {
            setSituacaoNfe(true);
            setNfe(response.data.content);
        } else {
            setSituacaoNfe(false);
            setNfe(response.data.content);
        }


        if (response.data.content.length < 20) {
            setDisabledNextButton(true);
        } else {
            setDisabledNextButton(false);
        }


    }

    function TablePaginationActions2() {
        const theme = useTheme();
        const { count, page, rowsPerPage } = props;

        const displayPageNumber = pagenumber + 1; // Adicione esta linha para calcular o número de página exibido para o usuário

        async function handleBackButtonClick() {
            if (pagenumber === 0) {
                setpageNumber(pagenumber);
            } else {
                setpageNumber(pagenumber - 1);
            }

            let queryParams = {
                page: pagenumber - 1,
            };

            if (ColigadaAux !== '') {
                queryParams.cnpj = ColigadaAux;
            }

            if (Chave !== '') {
                queryParams.chaveacesso = Chave.replace(/\s/g, '');
            }

            if (Datainicial !== '') {
                queryParams.datainicial = `${Datainicial}T00:00:00`;
            }

            if (Datafinal !== '') {
                queryParams.datafinal = `${Datafinal}T23:59:59`;
            }

            const queryString = new URLSearchParams(queryParams).toString();
            const response = await axiosInstance.get(`/api/v1/notaentradaprodutosefaz/paginado/?${queryString}`);
            setNfe(response.data.content);

            setTotalElements(response.data.totalElements);

            if (response.data.pageable.offset === 0) {
                setquantidadenfecount(response.data.pageable.pageSize);
            } else {
                setquantidadenfecount(response.data.pageable.offset);
            }

            if (response.data.content.length < 20) {
                setDisabledNextButton(true);
            } else {
                setDisabledNextButton(false);
            }
        }

        async function handleNextButtonClick() {
            if (pagenumber === totalPaginas) {
                setpageNumber(pagenumber);
            } else {
                setpageNumber(pagenumber + 1);
            }

            let queryParams = {
                page: pagenumber + 1,
            };

            if (ColigadaAux !== '') {
                queryParams.cnpj = ColigadaAux;
            }

            if (Chave !== '') {
                queryParams.chaveacesso = Chave.replace(/\s/g, '');
            }

            if (Datainicial !== '') {
                queryParams.datainicial = `${Datainicial}T00:00:00`;
            }

            if (Datafinal !== '') {
                queryParams.datafinal = `${Datafinal}T23:59:59`;
            }

            const queryString = new URLSearchParams(queryParams).toString();
            const response = await axiosInstance.get(`/api/v1/notaentradaprodutosefaz/paginado/?${queryString}`);
            setNfe(response.data.content);
            setTotalElements(response.data.totalElements);

            if (response.data.pageable.offset === 0) {
                setquantidadenfecount(response.data.pageable.pageSize);
            } else {
                setquantidadenfecount(response.data.pageable.offset);
            }

            if (response.data.content.length < 20) {
                setDisabledNextButton(true);
            } else {
                setDisabledNextButton(false);
            }
        }

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={pagenumber === 0}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>

                <IconButton>
                    {displayPageNumber} {/* Exibe o número da página para o usuário */}
                </IconButton>

                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={pagenumber >= totalPaginas || DisabledNextButton === true}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
            </Box>
        );
    }

    async function handleBuscarNfeResumo() {
        let url = '/api/v1/notaentradauxiliar/consultar/paginado';

        if (ColigadaAux !== '') {
            url += `?cnpjdestino=${ColigadaAux}`;
        }
        const listasituacaoenvio = [];
        if (StatusManifestacaoAutorizacaoUso) listasituacaoenvio.push("Autorização de Uso");
        if (StatusManifestacaoCienciaOperacao) listasituacaoenvio.push("Ciência da Operação");
        if (StatusManifestacaoCancelada) listasituacaoenvio.push("NF-e Cancelada", "Uso denegado");
        if (StatusManifestacaoConfirmacaoOperacao) listasituacaoenvio.push("Confirmação da Operação");
        if (listasituacaoenvio.length > 0) {
            const situacaoenvioParam = encodeURIComponent(listasituacaoenvio.join(','));
            url += ColigadaAux !== '' ? `&listasituacaoenvio=${situacaoenvioParam}` : `?listasituacaoenvio=${situacaoenvioParam}`;
        }
        if (ChaveAux !== '') {
            url += ColigadaAux !== '' || listasituacaoenvio.length > 0 ? `&chavedeacesso=${ChaveAux}` : `?chavedeacesso=${ChaveAux}`;
        }
        if (DatainicialAux !== '' && DatafinalAux !== '') {
            const dataInicial1 = moment(DatainicialAux, 'YYYY-MM-DD').format('YYYY-MM-DD') + 'T00:00:00';
            const dataFinal1 = moment(DatafinalAux, 'YYYY-MM-DD').format('YYYY-MM-DD') + 'T23:59:59';
            url += ColigadaAux !== '' || listasituacaoenvio.length > 0 || ChaveAux !== ''
                ? `&datainicial=${DatainicialAux !== '' ? `${dataInicial1}` : ''}&datafinal=${DatafinalAux !== '' ? `${dataFinal1}` : ''}`
                : `?datainicial=${DatainicialAux !== '' ? `${dataInicial1}` : ''}&datafinal=${DatafinalAux !== '' ? `${dataFinal1}` : ''}`;
        }

        const response = await axiosInstance.get(url);

        setTotalElementsResumo(response.data.totalElements);

        if (response.data.pageable.offset === 0) {
            setquantidadenfecountResumo(response.data.pageable.pageSize);
        } else {
            setquantidadenfecountResumo(response.data.pageable.offset);
        }

        if (response.data.content.length === 0) {
            setSituacaoNfeAux(true);
            setSituacaoNfeAuxDefault(false);
            setDisabledNextButtonResumo(true);
        } else {
            setSituacaoNfeAux(false);
            setSituacaoNfeAuxDefault(false);
            setExpanded(true);
            setNfeAux(response.data.content);
            if (response.data.content.length < 20) {
                setDisabledNextButtonResumo(true);
            } else {
                setDisabledNextButtonResumo(false);
            }
        }

    }

    function TablePaginationActions() {
        const theme = useTheme();
        const { count, page, rowsPerPage } = props;

        const displayPageNumberNfeResumo = pagenumber + 1;

        async function handleBackButtonClick() {
            try {
                if (pagenumber === 0) {
                    setpageNumber(pagenumber);
                } else {
                    setpageNumber(pagenumber - 1);
                }
                const newPage = pagenumber - 1;

                let url = '/api/v1/notaentradauxiliar/consultar/paginado';

                if (ColigadaAux !== '') {
                    url += `?cnpjdestino=${ColigadaAux}`;
                }
                const listasituacaoenvio = [];
                if (StatusManifestacaoAutorizacaoUso) listasituacaoenvio.push('Autorização de Uso');
                if (StatusManifestacaoCienciaOperacao) listasituacaoenvio.push('Ciência da Operação');
                if (StatusManifestacaoCancelada) listasituacaoenvio.push('NF-e Cancelada', 'Uso denegado');
                if (StatusManifestacaoConfirmacaoOperacao) listasituacaoenvio.push('Confirmação da Operação');
                if (listasituacaoenvio.length > 0) {
                    const situacaoenvioParam = encodeURIComponent(listasituacaoenvio.join(','));
                    url += ColigadaAux !== '' ? `&listasituacaoenvio=${situacaoenvioParam}` : `?listasituacaoenvio=${situacaoenvioParam}`;
                }
                if (ChaveAux !== '') {
                    url += ColigadaAux !== '' || listasituacaoenvio.length > 0 ? `&chavedeacesso=${ChaveAux}` : `?chavedeacesso=${ChaveAux}`;
                }
                if (DatainicialAux !== '' && DatafinalAux !== '') {
                    const dataInicial1 = moment(DatainicialAux, 'YYYY-MM-DD').format('YYYY-MM-DD') + 'T00:00:00';
                    const dataFinal1 = moment(DatafinalAux, 'YYYY-MM-DD').format('YYYY-MM-DD') + 'T23:59:59';
                    url += ColigadaAux !== '' || listasituacaoenvio.length > 0 || ChaveAux !== ''
                        ? `&datainicial=${DatainicialAux !== '' ? `${dataInicial1}` : ''}&datafinal=${DatafinalAux !== '' ? `${dataFinal1}` : ''}`
                        : `?datainicial=${DatainicialAux !== '' ? `${dataInicial1}` : ''}&datafinal=${DatafinalAux !== '' ? `${dataFinal1}` : ''}`;
                }

                const response = await axiosInstance.get(`${url}&page=${newPage}`);


                setTotalElementsResumo(response.data.totalElements);

                if (response.data.pageable.offset === 0) {
                    setquantidadenfecountResumo(response.data.pageable.pageSize);
                } else {
                    setquantidadenfecountResumo(response.data.pageable.offset);
                }

                if (response.data.content.length === 0) {
                    setSituacaoNfeAux(true);
                    setSituacaoNfeAuxDefault(false);
                    setDisabledNextButtonResumo(true);
                } else {
                    setSituacaoNfeAux(false);
                    setSituacaoNfeAuxDefault(false);
                    setExpanded(true);
                    setNfeAux(response.data.content);
                    if (response.data.content.length < 20) {
                        setDisabledNextButtonResumo(true);
                    } else {
                        setDisabledNextButtonResumo(false);
                    }
                }
            } catch (error) {
                // Tratar o erro, se necessário
            }
        };

        async function handleNextButtonClick() {
            try {
                if (pagenumber === totalPaginas) {
                    setpageNumber(pagenumber);
                } else {
                    setpageNumber(pagenumber + 1);
                }
                const newPage = pagenumber + 1;

                let url = '/api/v1/notaentradauxiliar/consultar/paginado';

                if (ColigadaAux !== '') {
                    url += `?cnpjdestino=${ColigadaAux}`;
                }
                const listasituacaoenvio = [];
                if (StatusManifestacaoAutorizacaoUso) listasituacaoenvio.push('Autorização de Uso');
                if (StatusManifestacaoCienciaOperacao) listasituacaoenvio.push('Ciência da Operação');
                if (StatusManifestacaoCancelada) listasituacaoenvio.push('NF-e Cancelada', 'Uso denegado');
                if (StatusManifestacaoConfirmacaoOperacao) listasituacaoenvio.push('Confirmação da Operação');
                if (listasituacaoenvio.length > 0) {
                    const situacaoenvioParam = encodeURIComponent(listasituacaoenvio.join(','));
                    url += ColigadaAux !== '' ? `&listasituacaoenvio=${situacaoenvioParam}` : `?listasituacaoenvio=${situacaoenvioParam}`;
                }
                if (ChaveAux !== '') {
                    url += ColigadaAux !== '' || listasituacaoenvio.length > 0 ? `&chavedeacesso=${ChaveAux}` : `?chavedeacesso=${ChaveAux}`;
                }
                if (DatainicialAux !== '' && DatafinalAux !== '') {
                    const dataInicial1 = moment(DatainicialAux, 'YYYY-MM-DD').format('YYYY-MM-DD') + 'T00:00:00';
                    const dataFinal1 = moment(DatafinalAux, 'YYYY-MM-DD').format('YYYY-MM-DD') + 'T23:59:59';
                    url += ColigadaAux !== '' || listasituacaoenvio.length > 0 || ChaveAux !== ''
                        ? `&datainicial=${DatainicialAux !== '' ? `${dataInicial1}` : ''}&datafinal=${DatafinalAux !== '' ? `${dataFinal1}` : ''}`
                        : `?datainicial=${DatainicialAux !== '' ? `${dataInicial1}` : ''}&datafinal=${DatafinalAux !== '' ? `${dataFinal1}` : ''}`;
                }

                const response = await axiosInstance.get(`${url}&page=${newPage}`);



                setTotalElementsResumo(response.data.totalElements);

                if (response.data.content.length === 0) {
                    setSituacaoNfeAux(true);
                    setSituacaoNfeAuxDefault(false);
                    setDisabledNextButtonResumo(true);
                } else {
                    setSituacaoNfeAux(false);
                    setSituacaoNfeAuxDefault(false);
                    setExpanded(true);
                    setNfeAux(response.data.content);
                    if (response.data.content.length < 20) {
                        setDisabledNextButtonResumo(true);
                    } else {
                        setDisabledNextButtonResumo(false);
                    }
                }
            } catch (error) {
                // Tratar o erro, se necessário
            }
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>

                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>

                <IconButton>
                    {displayPageNumberNfeResumo}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1 || DisabledNextButtonResumo === true}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>

            </Box>
        );
    }

    React.useEffect(() => {
        const numSelected = selectedRows.length;
        const message =
            numSelected === 0
                ? "Você não selecionou nenhum documento para a manifestação do destinatário."
                : numSelected === 1
                    ? "Você selecionou 1 documento para a manifestação do destinatário."
                    : `Você selecionou ${numSelected} documentos para a manifestação do destinatário.`;
        setNumSelected(numSelected);
        setMessage(message);
    }, [selectedRows]);

    useEffect(() => {
        async function nomefantasiacoligada() {
            const response = await axiosInstance.get('/coligadas');
            setComboColigadasFornecedor(response.data);


        }


        nomefantasiacoligada();

        setTimeout(() => {
            setloading(true);



            setTimeout(() => {
                setcompleted(true);
            }, 300);
        });


        async function loadingnfe() {
            const response = await axiosInstance.get('/api/v1/notaentradaprodutosefaz/paginado/')
                .then(resp => {

                    setNfe(resp.data.content);
                    setpageNumber(resp.data.number)
                    setTotalpages(resp.data.totalPages);
                    setSituacaoNfeAuxDefault(true);
                    setExpanded(true);

                    setTotalElements(resp.data.totalElements);

                    if (resp.data.pageable.offset === 0) {
                        setquantidadenfecount(resp.data.pageable.pageSize);
                    } else {
                        setquantidadenfecount(resp.data.pageable.offset);
                    }
                }).catch(error => {

                });
        }
        loadingnfe();

        async function totaluso() {
            const response = await axiosInstance.get('/api/v1/notaentradauxiliar/quantidadenfeautorizacaouso')
                .then(resp => {
                    settotalautorizacaodeuso(resp.data);
                }).catch(error => {

                });
        }
        totaluso();

        async function totalciencia() {
            const response = await axiosInstance.get('/api/v1/notaentradauxiliar/quantidadenfecienciaoperacao')
                .then(resp => {
                    settotalciencia(resp.data);
                }).catch(error => {

                });
        }
        totalciencia();

        async function totalconfirma() {
            const response = await axiosInstance.get('/api/v1/notaentradauxiliar/quantidadenfeconfirmacaooperacao')
                .then(resp => {
                    settotalconfirmacao(resp.data);
                }).catch(error => {

                });
        }
        totalconfirma();

        async function totaldesconhecido() {
            const response = await axiosInstance.get('/api/v1/notaentradauxiliar/quantidadenfedesconhecimentooperacao')
                .then(resp => {
                    settotaldesconhecimentooperacao(resp.data);
                }).catch(error => {

                });
        }
        totaldesconhecido();

        async function naorealizada() {
            const response = await axiosInstance.get('/api/v1/notaentradauxiliar/quantidadenfecancelada')
                .then(resp => {
                    settotalnaorealizada(resp.data);
                }).catch(error => {

                });
        }

        naorealizada();

        async function loadingNfeAuxiliar() {
            const response = await axiosInstance.get('/api/v1/notaentradauxiliar/paginado/')
                .then(resp => {
                    setNfeAux(resp.data.content);
                    if (resp.data.content.length === 0) {
                        setSituacaoNfeAux(true);
                    }
                }).catch(error => {

                })
        }

        loadingNfeAuxiliar();
        async function quantdidade() {
            const response = await axiosInstance.get('/api/v1/notaentradaprodutosefaz/totalnfe')
                .then(resp => {
                    setQuantidadenfe(resp.data);
                }).catch(error => {
                    setTimeout(() => { window.location.href = '/'; }, 2000);
                });
        }
        quantdidade();

        async function quantdidadeJCR() {
            const response = await axiosInstance.get('/api/v1/notaentradaprodutosefaz/totalnfecoligada/77532521000114')
                .then(resp => {
                    setQuantidadenfeJCR(resp.data);
                }).catch(error => {
                    setTimeout(() => { window.location.href = '/'; }, 2000);
                });
        }
        quantdidadeJCR();


        async function quantdidadeEBPS() {
            const response = await axiosInstance.get('/api/v1/notaentradaprodutosefaz/totalnfecoligada/76527407000133')
                .then(resp => {
                    setQuantidadenfeEBPS(resp.data);
                }).catch(error => {
                    setTimeout(() => { window.location.href = '/'; }, 2000);
                });
        }
        quantdidadeEBPS();



        async function quantdidadeSEAGULL() {
            const response = await axiosInstance.get('/api/v1/notaentradaprodutosefaz/totalnfecoligada/81488041000170')
                .then(resp => {
                    setQuantidadenfeSEAGULL(resp.data);
                }).catch(error => {
                    setTimeout(() => { window.location.href = '/'; }, 2000);
                });
        }
        quantdidadeSEAGULL();

        async function nomefantasiacoligada() {
            const response = await axiosInstance.get('/coligadas');
            setComboColigadasFornecedor(response.data);

        }


        nomefantasiacoligada();


        async function quantdidadeFR4() {
            const response = await axiosInstance.get('/api/v1/notaentradaprodutosefaz/totalnfecoligada/82527094000116')
                .then(resp => {
                    setQuantidadenfFR4(resp.data);
                }).catch(error => {
                    setTimeout(() => { window.location.href = '/'; }, 2000);
                });
        }
        quantdidadeFR4();

        async function quantdidadeLOG() {
            const response = await axiosInstance.get('/api/v1/notaentradaprodutosefaz/totalnfecoligada/12568743000141')
                .then(resp => {
                    setQuantidadenfLOG(resp.data);
                }).catch(error => {
                    setTimeout(() => { window.location.href = '/'; }, 2000);
                });
        }
        quantdidadeLOG();




    }, []);
    nfeaux.forEach(row => {
        const dateLimit = new Date(new Date().getTime() - (10 * 24 * 60 * 60 * 1000));
        const emissao = new Date(row.dhEmi);
        const diffDays = Math.floor((today - emissao) / (1000 * 60 * 60 * 24));


        if (row.situacaoenvio !== 'Confirmação da Operação' && row.situacaoenvio !== 'Ciência da Operação' && new Date(row.dhEmi) <= dateLimit) {
            countCiencia++;
        }

        if (row.situacaoenvio == 'Autorização de Uso' && diffDays >= 180) { // verifica se a diferença é menor ou igual a 180 dias
            countForaPrazo++;
        }

    });


    const handleKeyDown = (event) => {
        if (event.keyCode === 13) { // Verifica se a tecla pressionada é Enter (código 13)
            handleBuscarNfeResumo(); // Chama a função de busca
        }
    };

    return (
        <div className="wrapper">
            <Header />
            <SideBar />
            <div className='content-wrapper'>
                <div>
                    <div class="card-header">
                        <section class="content-header">
                            <h1><i class="fa fa-plus-square fa-1x cor-padrao"></i> NF-e
                                <small></small>
                            </h1>
                            <ol class="breadcrumb">
                                <li><i class="fa fa-home"></i> Portal JCR</li>
                                <li><i class="fa fa-book"></i> Documento de Entrada</li>
                                <li class="active"><i class="fa fa-file-archive-o"></i> NF-e</li>

                            </ol>
                        </section>
                    </div>
                </div>
                <br></br>
                <div className="content">
                    <div class="ui-panel ui-widget ui-widget-content ui-corner-all box box-danger"><div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" class="ui-panel-content ui-widget-content">
                        <div class="box-header with-border">
                            <div class="form-horizontal">
                                <div class="box-body">
                                    <div class="col-md-12">
                                        <Tabs defaultActiveKey="resumo" transition={false} id="noanim-tab-example" className="mb-3">
                                            <Tab eventKey="resumo" title="NF-e Resumo">
                                                <div class=" ui-panel ui-widget ui-widget-content ui-corner-all box box-danger ">
                                                    <div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" class="ui-panel-content ui-widget-content">
                                                        <div class="box-header with-border ">
                                                            <Card>
                                                                <div>
                                                                    <div class=" ">
                                                                        <div class="card">
                                                                            <br></br>
                                                                            <div className=''>
                                                                                <div className="col-md-3 col-sm-3 col-12 custom-box">
                                                                                    <div className="small-box bg" style={{ background: '#fdc003', color: '#ffffff' }}>
                                                                                        <div className="inner">
                                                                                            <h4>Total: {totalautorizacaodeuso}</h4>
                                                                                            <p style={{ fontSize: '12px' }}>Autorização de Uso</p>
                                                                                        </div>
                                                                                        <div className="icon">
                                                                                            <i className="ion ion-bag"></i>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-3 col-sm-3 col-12 custom-box">
                                                                                    <div className="small-box bg" style={{ background: '#0d2a7a', color: '#ffffff' }}>
                                                                                        <div className="inner">
                                                                                            <h4>Total: {totalciencia}</h4>
                                                                                            <p style={{ fontSize: '12px' }}>Ciência da Operação</p>
                                                                                        </div>
                                                                                        <div className="icon">
                                                                                            <i className="ion ion-bag"></i>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-3 col-sm-3 col-12 custom-box">
                                                                                    <div className="small-box bg" style={{ background: '#2bb673', color: '#ffffff' }}>
                                                                                        <div className="inner">
                                                                                            <h4>Total: {totalconfirmacao}</h4>
                                                                                            <p style={{ fontSize: '12px' }}>Confirmação da Operação</p>
                                                                                        </div>
                                                                                        <div className="icon">
                                                                                            <i className="ion ion-bag"></i>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-3 col-sm-3 col-12 custom-box">
                                                                                    <div className="small-box bg" style={{ background: '#fd0100', color: '#ffffff' }}>
                                                                                        <div className="inner">
                                                                                            <h4>Total:{totalnaorealizada}</h4>
                                                                                            <p style={{ fontSize: '12px' }}>Denegada / Cancelada</p>
                                                                                        </div>
                                                                                        <div className="icon">
                                                                                            <i className="ion ion-bag"></i>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>





                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br></br>
                                                                <div class="card">
                                                                    <div className="ui-panel ui-widget ui-widget-content ui-corner-all">

                                                                        <div className="card-container colorpersonz">
                                                                            <p className="col-sm-6 col-md-3 control-label" sx={{ fontWeight: 'bold' }}><i class="fa fa-filter"></i>Filtro NF-e Resumo</p>

                                                                            {expanded && (
                                                                                <div className=''>
                                                                                    <div className="card-content">
                                                                                        <div class="form-horizontal">
                                                                                            <br></br>
                                                                                            <div class="">
                                                                                                <br></br>
                                                                                                <div class="form-group">
                                                                                                    <div class="col-sm-12 col-md-3 control-label">
                                                                                                        <label for="selectPrioridade">Chave de Acesso: </label>
                                                                                                    </div>
                                                                                                    <div class="form-group">
                                                                                                        <div class="col-sm-1 col-md-3">
                                                                                                            <input id="filtersefazForm:titulo"
                                                                                                                onKeyDown={handleKeyDown}
                                                                                                                value={ChaveAux}
                                                                                                                onChange={(ev) => setChaveAux(ev.target.value)}
                                                                                                                type="text" name="filtersefazForm:titulo" class="form-control" />
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>


                                                                                                <div class="form-group">

                                                                                                    <div className="col-sm-12 col-md-3 control-label">
                                                                                                        <label for="selectPrioridade">Coligada:* </label>
                                                                                                    </div>
                                                                                                    <div class="col-sm-2">

                                                                                                        <select selected="selected" class="form-control select2 select2-hidden-accessible" multiple=""
                                                                                                            style={{ height: 35 }} onChange={(ev) => setColigadaAux(ev.target.value)}>
                                                                                                            <option value="">Selecione</option>
                                                                                                            {ComboColigadasFornecedor.map((row) => (

                                                                                                                <option value={row.cnpj}>{row.nomefantasia}</option>

                                                                                                            ))}
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </div>


                                                                                                <div class="form-group">
                                                                                                    <div class="col-sm-3 col-md-3 control-label">
                                                                                                        <label for="selectPrioridade">Período: </label>
                                                                                                    </div>
                                                                                                    <div class="form-group">
                                                                                                        <div class="col-sm-3 col-md-3 control-label">
                                                                                                            <Stack spacing={8}>
                                                                                                                <input type="date"
                                                                                                                    value={DatainicialAux}
                                                                                                                    onChange={(ev) => setDatainicialAux(ev.target.value)}
                                                                                                                    min="01/01/2022"
                                                                                                                    max="01/01/2030"></input>
                                                                                                            </Stack></div>
                                                                                                        <div class="">
                                                                                                            <div class="col-sm-3 col-md-3 control-label">
                                                                                                                <Stack spacing={1}>
                                                                                                                    <input type="date"
                                                                                                                        value={DatafinalAux}
                                                                                                                        onChange={(ev) => setDatafinalAux(ev.target.value)}
                                                                                                                        min="01/01/2022"
                                                                                                                        max="01/01/2030"></input>

                                                                                                                </Stack>

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>

                                                                                                <div class="form-group">
                                                                                                    <div class="col-sm-12 col-md-3 control-label"  >
                                                                                                        <label for="selectPrioridade">Situação Manifestação: </label>
                                                                                                    </div>
                                                                                                    <div class="col-sm-8">
                                                                                                        <div class="card">
                                                                                                            <div class="card-body">

                                                                                                                <div class="form-check form-check-inline">
                                                                                                                    <input class="form-check-input form-check-lg" type="checkbox" value="Autorização de Uso" checked={StatusManifestacaoAutorizacaoUso} onChange={(e) => setStatusManifestacaoAutorizacaoUso(e.target.checked)} />
                                                                                                                    <label class="form-check-label mt-2" for="checkbox1">
                                                                                                                        Autorização de Uso
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                                <div class="form-check form-check-inline">
                                                                                                                    <input class="form-check-input form-check-lg" type="checkbox" value="Ciência da Operação" checked={StatusManifestacaoCienciaOperacao} onChange={(e) => setStatusManifestacaoCienciaOperacao(e.target.checked)} />
                                                                                                                    <label class="form-check-label mt-2" for="checkbox2">
                                                                                                                        Ciência da Operação
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                                <div class="form-check form-check-inline">
                                                                                                                    <input class="form-check-input form-check-lg" type="checkbox" value="Confirmação da Operação" checked={StatusManifestacaoConfirmacaoOperacao} onChange={(e) => setStatusManifestacaoConfirmacaoOperacao(e.target.checked)} />
                                                                                                                    <label class="form-check-label mt-2" for="checkbox4">
                                                                                                                        Confirmação da Operação
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                                <div class="form-check form-check-inline">
                                                                                                                    <input class="form-check-input form-check-lg" type="checkbox" value="Cancelada" checked={StatusManifestacaoCancelada} onChange={(e) => setStatusManifestacaoCancelada(e.target.checked)} />
                                                                                                                    <label class="form-check-label mt-2" for="checkbox3">
                                                                                                                        Denegada/Cancelada
                                                                                                                    </label>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>


                                                                                                <div class="box-footer">
                                                                                                    <button type="button" class="btn btn-primary col-xs-12 col-sm-5" style={{ height: 35 }} onClick={() => refresh()}>
                                                                                                        <i class="fa-solid fa-refresh" ></i> Limpar</button>
                                                                                                    <div class="col-sm-1"></div>
                                                                                                    <button type="button" class="btn btn-success col-xs-12 col-sm-6" style={{ height: 35 }} onClick={() => handleBuscarNfeResumo()}>
                                                                                                        <i class="fa-solid fa-magnifying-glass" ></i> Buscar</button>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            )}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card>

                                                        </div>

                                                    </div>
                                                    <div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" class="ui-panel-content ui-widget-content">
                                                        <>
                                                            {SituacaoNfeAuxDefault === true ? (
                                                                <div>

                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <div class="box-header with-border ">
                                                                        <div class="form-horizontal card scrollBox2  ">
                                                                            {!completed ? (
                                                                                <>
                                                                                    {!loading ? (
                                                                                        <Dialog
                                                                                            open={true}

                                                                                            aria-describedby="alert-dialog-slide-description"

                                                                                        >
                                                                                            <div className="pos-center">
                                                                                                <Spinner />
                                                                                            </div>
                                                                                        </Dialog>
                                                                                    ) : (
                                                                                        <Dialog
                                                                                            open={true}

                                                                                            aria-describedby="alert-dialog-slide-description"

                                                                                        >
                                                                                            <div className="pos-center">
                                                                                                <Spinner />
                                                                                            </div>
                                                                                        </Dialog>
                                                                                    )}
                                                                                </>
                                                                            ) : (


                                                                                <>
                                                                                    {SituacaoNfeAux === true ?
                                                                                        <div>
                                                                                            <h4 style={{ paddingLeft: '35%' }} >Nenhum Documento Localizado!</h4>
                                                                                        </div>
                                                                                        :
                                                                                        <div className=''>


                                                                                            <Table>
                                                                                                <TableHead>
                                                                                                    <TableRow>
                                                                                                        <TableCell padding="checkbox">
                                                                                                            <Checkbox
                                                                                                                checked={selectedRows.length > 0}

                                                                                                                indeterminate={selectedRows.length > 0 && selectedRows.length < nfeaux.length}

                                                                                                            />
                                                                                                        </TableCell>
                                                                                                        <TableCell>Chave de Acesso</TableCell>
                                                                                                        <TableCell>Cnpj</TableCell>
                                                                                                        <TableCell>Empresa</TableCell>
                                                                                                        <TableCell>Situação</TableCell>
                                                                                                        <TableCell>Situação Manifesto</TableCell>
                                                                                                        <TableCell>Fornecedor</TableCell>
                                                                                                        <TableCell>Data da Emissão</TableCell>
                                                                                                        <TableCell>Data da Coleta</TableCell>
                                                                                                        <TableCell>Valor</TableCell>
                                                                                                    </TableRow>
                                                                                                </TableHead>
                                                                                                <TableBody>

                                                                                                    {nfeaux.map((row) => (

                                                                                                        <TableRow
                                                                                                            key={row.chNFe}

                                                                                                            selected={selectedRows.indexOf(row.id) !== -1}
                                                                                                            onClick={(event) => handleRowSelect(event, row.chNFe, row.empresadestino, row.cnpjdestino, row.dhEmi, row.situacaoenvio)}
                                                                                                        >
                                                                                                            <TableCell padding="checkbox">
                                                                                                                <Checkbox

                                                                                                                />
                                                                                                            </TableCell>
                                                                                                            <TableCell>{row.chNFe}</TableCell>
                                                                                                            <TableCell>{row.cnpjdestino}</TableCell>
                                                                                                            <TableCell>{row.empresadestino}</TableCell>
                                                                                                            <TableCell>{row.situacaoenvio === null && row.situacaoenvio === 'Confirmação da Operação' || row.situacaoenvio === 'Autorização de Uso' ?
                                                                                                                <img src={SefazStatusEdit} height="30"></img> :
                                                                                                                row.situacaoenvio === 'Ciência da Operação' ?
                                                                                                                    <img src={CienciaDaOperacaoIcon} height="30"></img> :

                                                                                                                    row.situacaoenvio === 'NF-e Cancelada' ?
                                                                                                                        <img src={DenagadaIcon} height="30"></img> :

                                                                                                                        row.situacaoenvio === 'Uso denegado'
                                                                                                                            ? <img src={DenagadaIcon} height="30"></img> :
                                                                                                                            row.situacaoenvio === 'Confirmação da Operação' ? <img src={SefazIcon} height="30"></img> : null}</TableCell>

                                                                                                            <Tooltip arrow sx={{ fontSize: '16px' }} title={row.situacaoenvio === 'Uso denegado' ? 'Uso Denegado: Irregularidade fiscal do destinatário' : row.situacaoenvio}><TableCell>{row.situacaoenvio !== null ? row.situacaoenvio : 'Autorização de Uso'}</TableCell></Tooltip>
                                                                                                            <TableCell>{row.xNome}</TableCell>

                                                                                                            <TableCell style={{ color: (row.situacaoenvio === 'Confirmação da Operação' || row.situacaoenvio === 'Ciência da Operação') ? 'inherit' : (new Date(row.dhEmi) <= new Date(new Date().getTime() - (10 * 24 * 60 * 60 * 1000)) ? 'red' : 'inherit') }}>
                                                                                                                {new Date(row.dhEmi).toLocaleString('pt-BR', { dateStyle: 'short', timeStyle: 'medium' }).replace(",", "")}
                                                                                                            </TableCell>
                                                                                                            <TableCell>{new Date(row.datacoleta).toLocaleString('pt-BR', { dateStyle: 'short', timeStyle: 'medium' }).replace(",", "")}</TableCell>
                                                                                                            <TableCell>{row.vnf}</TableCell>
                                                                                                            <Modal
                                                                                                                open={popStatusManifestacao}
                                                                                                                onClose={handleFecharCloseCrud}
                                                                                                                aria-labelledby="parent-modal-title"
                                                                                                                aria-describedby="parent-modal-description"
                                                                                                            >
                                                                                                                <Box sx={{ ...style, width: '50%' }}>

                                                                                                                    <div class="form-horizontal">
                                                                                                                        <div className='content'>

                                                                                                                            <div class="box-body">


                                                                                                                                <h2 id="parent-modal-title"><img src={Builhorn}
                                                                                                                                    height="50"></img> Manifestação da NF-e</h2>

                                                                                                                                <div class="form-group">
                                                                                                                                    <br></br>
                                                                                                                                    <div class="col-sm-2 col-md-8 control-label">
                                                                                                                                        <p>{message}</p>
                                                                                                                                    </div>

                                                                                                                                </div>

                                                                                                                                <div class="form-group">
                                                                                                                                    <br></br>
                                                                                                                                    <div class="col-sm-2 col-md-3 control-label">
                                                                                                                                        <label for="solicitante">Manifestação de Destinatário:</label>
                                                                                                                                    </div>
                                                                                                                                    <div class="col-sm-12 col-md-8 control-label">
                                                                                                                                        <select style={{ height: '35px' }}
                                                                                                                                            id="prioridades"
                                                                                                                                            value={selectedOption}
                                                                                                                                            onChange={handleOptionChange}
                                                                                                                                            class="form-control"
                                                                                                                                        >
                                                                                                                                            <option value="">Selecione uma Opção*</option>
                                                                                                                                            <option value="CienciadaOperacao">Ciência da Operação</option>
                                                                                                                                            <option value="ConfirmacaoOperacao">Confirmação da Operação</option>
                                                                                                                                            <option value="DesconhecidoOperacao">Desconhecimento da Operação</option>
                                                                                                                                            <option value="OperacaoNaorealizada">Operação não Realizada</option>
                                                                                                                                        </select>
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                                <Divider />
                                                                                                                                <br></br>

                                                                                                                                <div class="form-group">

                                                                                                                                    <div class="col-sm-12 col-md-6">
                                                                                                                                        <button type="button" class="btn btn-block btn-danger btn-sm"
                                                                                                                                            onClick={handleFecharCloseCrud}
                                                                                                                                        >
                                                                                                                                            <i class="fa fa-chevron-left"></i> VOLTAR</button>
                                                                                                                                    </div>

                                                                                                                                    <div class="col-sm-12 col-md-6">
                                                                                                                                        <button type="button" class="btn btn-block btn-primary btn-sm"

                                                                                                                                            onClick={handleManifestarAPI}
                                                                                                                                        >
                                                                                                                                            <i class="fa fa-bullhorn" ></i> Manifestar</button>
                                                                                                                                        <br></br>
                                                                                                                                    </div>


                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>


                                                                                                                </Box>
                                                                                                            </Modal>
                                                                                                        </TableRow>
                                                                                                    ))}

                                                                                                </TableBody>
                                                                                            </Table>
                                                                                        </div>
                                                                                    }
                                                                                </>

                                                                            )}
                                                                        </div>
                                                                        {/* NFE- RESUMO */}
                                                                        <div class="cardmov">
                                                                            <TablePagination
                                                                                colSpan={3}
                                                                                component="div"
                                                                                rowsPerPageOptions={[20]}
                                                                                count={quantidadenfecountResumo}
                                                                                rowsPerPage={nfeaux.length}
                                                                                page={pagenumber}
                                                                                ActionsComponent={TablePaginationActions}

                                                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                                                labelDisplayedRows={({ from, to, count }) => (
                                                                                    <>
                                                                                        <p><strong>Total de notas do período:       {totalElementsNfeResumo}</strong></p>
                                                                                        <div class="custom-label">
                                                                                            <p><strong>Total de registros por página:        20</strong></p>
                                                                                        </div>
                                                                                    </>
                                                                                )}


                                                                            />

                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            )}
                                                        </>
                                                    </div>
                                                </div>


                                                <div class="form-group">


                                                    <div class="row">
                                                        <div class="col-xs-12 col-md-12 control-label">

                                                            <div class="desktop-buttons">


                                                                <button type="button" class="btn btn-primary btn-lg" onClick={() => refresh()}>
                                                                    <i class="fa fa-refresh"></i> Limpar Filtro</button>
                                                                <button type="button" class="btn btn-success btn-lg" disabled={isDeleteButtonDisabled || disableManifestButton} onClick={handleManifestar}>
                                                                    <i class="fa fa-floppy-o"></i> Manifestar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                            {/* NFE COMPLETA*/}
                                            <Tab eventKey="completa" title="NF-e Completo">
                                                <div class="container-fluid ">
                                                    <div class="container-fluid  card-body card ">
                                                        <div className="d-flex flex-wrap">
                                                            <div className="cardpersonalizadocd col-md-2 col-12">
                                                                <span className="info-box-text"> <img src={imagemNfe} height="45" alt="Logo NFe" style={estiloImagem} />  </span>
                                                                <span className="info-box-number">Total: {quantidade}</span>
                                                            </div>

                                                            <div className="cardpersonalizadocd col-md-2 col-12">
                                                                <span className="info-box-text"> <img src={imagemLogoJCR} height="45" alt="Logo NFe" style={estiloImagem} />   </span>
                                                                <span className="info-box-number">Total: {quantidadeJCR}</span>
                                                            </div>

                                                            <div className="cardpersonalizadocd col-md-2 col-12">
                                                                <span className="info-box-text"> <img src={EbpsIcon} height="45" alt="Logo NFe" style={estiloImagem} />  </span>
                                                                <span className="info-box-number">Total: {quantidadeEBPS}</span>
                                                            </div>

                                                            <div className="cardpersonalizadocd col-md-2 col-12">
                                                                <span className="info-box-text"> <img src="http://grupojcr.com.br/site/wp-content/uploads/2017/07/logo-seagull-1.jpg" height="45" alt="Logo NFe" style={estiloImagem} />  </span>
                                                                <span className="info-box-number">Total: {quantidadeSEAGULL}</span>
                                                            </div>

                                                            <div className="cardpersonalizadocd col-md-2 col-12">
                                                                <span className="info-box-text"> <img src={imagemFR4} height="45" alt="Logo NFe" style={estiloImagem} />  </span>
                                                                <span className="info-box-number">Total: {quantidadeFR4}</span>
                                                            </div>

                                                            <div className="cardpersonalizadocd col-md-2 col-12">
                                                                <span className="info-box-text"> <img src={imagemLog} height="45" alt="Logo NFe" style={estiloImagem} />   </span>
                                                                <span className="info-box-number">Total: {quantidadeLOG}</span>
                                                            </div>
                                                        </div>
                                                        <div class="">

                                                            <p className="col-sm-6 col-md-3 control-label" sx={{ fontWeight: 'bold' }}><i class="fa fa-filter"></i>Filtro NF-e Completa</p>
                                                        </div>


                                                        <div class="box-footer">
                                                        </div>

                                                        <div class="form-horizontal">

                                                            <div class="">
                                                                <div>
                                                                    <div class="form-group">
                                                                        <div class="col-sm-6 col-md-3 control-label">
                                                                            <label for="selectPrioridade">Chave Acesso: </label>
                                                                        </div>
                                                                        <div class="col-sm-3">
                                                                            <input id="chamadoForm:titulo" type="text" value={Chave} onChange={(ev) => setChave(ev.target.value)} name="chamadoForm:titulo" class="form-control" maxLength="100" />

                                                                        </div>
                                                                    </div>
                                                                    <div class="form-group">

                                                                        <div className="col-sm-12 col-md-3 control-label">
                                                                            <label for="selectPrioridade">Coligada:* </label>
                                                                        </div>
                                                                        <div class="col-sm-2">

                                                                            <select selected="selected" class="form-control select2 select2-hidden-accessible" multiple=""
                                                                                style={{ height: 35 }} onChange={(ev) => setColigadaAux(ev.target.value)}>
                                                                                <option value="">Selecione</option>
                                                                                {ComboColigadasFornecedor.map((row) => (

                                                                                    <option value={row.cnpj}>{row.nomefantasia}</option>

                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <div class="col-sm-3 col-md-3 control-label">
                                                                            <label for="selectPrioridade">Período: </label>
                                                                        </div>
                                                                        <div class="form-group">
                                                                            <div class="col-sm-3 col-md-3 control-label">
                                                                                <Stack spacing={8}>
                                                                                    <input type="date"
                                                                                        value={Datainicial}
                                                                                        onChange={(ev) => setDatainicial(ev.target.value)}
                                                                                        min="01/01/2022"
                                                                                        max="01/01/2030"></input>
                                                                                </Stack></div>
                                                                            <div class="">
                                                                                <div class="col-sm-3 col-md-3 control-label">
                                                                                    <Stack spacing={1}>
                                                                                        <input type="date"
                                                                                            value={Datafinal}
                                                                                            onChange={(ev) => setDatafinal(ev.target.value)}
                                                                                            min="01/01/2022"
                                                                                            max="01/01/2030"></input>

                                                                                    </Stack>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>


                                                                <div class="box-footer">
                                                                    <button type="button" class="btn btn-primary col-xs-12 col-sm-5" style={{ height: 35 }} onClick={() => refresh()}>
                                                                        <i class="fa-solid fa-refresh" ></i>Limpar Filtro</button>
                                                                    <div class="col-sm-1"></div>
                                                                    <button type="button" class="btn btn-success col-xs-12 col-sm-6" style={{ height: 35 }} onClick={() => handleBuscarNfeCompleta()}>
                                                                        <i class="fa-solid fa-magnifying-glass" ></i>Buscar</button>

                                                                </div>



                                                            </div>

                                                        </div>

                                                    </div>




                                                </div>


                                                <div className='content'>
                                                    <div class=" ui-panel ui-widget ui-widget-content ui-corner-all box box-danger ">
                                                        <div id="edicaoCategoriaChamadoForm:painelSubcategoria_content" class="ui-panel-content ui-widget-content">
                                                            <div class="box-header with-border ">
                                                                <div class="form-horizontal card scrollBox2  ">
                                                                    {!completed ? (
                                                                        <>
                                                                            {!loading ? (
                                                                                <Dialog
                                                                                    open={true}

                                                                                    aria-describedby="alert-dialog-slide-description"

                                                                                >
                                                                                    <div className="pos-center">
                                                                                        <Spinner />
                                                                                    </div>
                                                                                </Dialog>
                                                                            ) : (
                                                                                <Dialog
                                                                                    open={true}

                                                                                    aria-describedby="alert-dialog-slide-description"

                                                                                >
                                                                                    <div className="pos-center">
                                                                                        <Spinner />
                                                                                    </div>
                                                                                </Dialog>
                                                                            )}
                                                                        </>
                                                                    ) : (


                                                                        <>
                                                                            {SituacaoNfe === true ?
                                                                                <div>
                                                                                    <h4 style={{ paddingLeft: '35%' }} >Nenhum Documento Localizado!</h4>
                                                                                </div>
                                                                                :
                                                                                <div className=''>
                                                                                    <Table className="table " stickyHeader aria-label="sticky table">
                                                                                        <TableHead >

                                                                                            <TableRow >
                                                                                                <TableCell sx={{ fontWeight: 'bold' }}>Visualizar Nfe</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 'bold' }}>Gerar PDF</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 'bold' }}>Gerar XML</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 'bold' }}>Modelo</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 'bold' }}>Chave de Acesso</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 'bold' }}>Número</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 'bold' }}>Data Emissão</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 'bold' }}>Cnpj/Cpf - Prestador</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 'bold' }}>Nome Fantasia - Prestador</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 'bold' }}>Cidade Origem</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 'bold' }}>Cnpj/Cpf - Tomador</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 'bold' }}>Nome Fantasia - Tomador</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 'bold' }}>Valor da Nota</TableCell>
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        <TableBody>


                                                                                            {nfe.map((row) => (

                                                                                                <TableRow
                                                                                                    key={row.id}
                                                                                                    sx={{ border: 100, width: 100 }}
                                                                                                >
                                                                                                    <Tooltip title={"Gerar Visualizar Nfe: " + row.chave}><TableCell component="th" scope="row">
                                                                                                        <a>
                                                                                                            <img src="https://d1nhio0ox7pgb.cloudfront.net/_img/g_collection_png/standard/512x512/document_view.png"
                                                                                                                width="30" height="30" onClick={() => consultaDetalhada(row.chave)}

                                                                                                            >
                                                                                                            </img>
                                                                                                        </a>
                                                                                                    </TableCell></Tooltip>

                                                                                                    <Tooltip title={"Gerar Visualizar Nfe: " + row.chave}><TableCell component="th" scope="row">
                                                                                                        <a>
                                                                                                            <img src="https://play-lh.googleusercontent.com/BkRfMfIRPR9hUnmIYGDgHHKjow-g18-ouP6B2ko__VnyUHSi1spcc78UtZ4sVUtBH4g"
                                                                                                                width="30" height="30" onClick={() => gerarPdf(row.chave)}

                                                                                                            >
                                                                                                            </img>
                                                                                                        </a>
                                                                                                    </TableCell></Tooltip>


                                                                                                    <Tooltip title={"Gerar XML: " + row.chave}><TableCell component="th" scope="row">
                                                                                                        <a>
                                                                                                            <img src="https://w7.pngwing.com/pngs/567/540/png-transparent-yaml-computer-icons-xml-document-file-format-mp4-icon-blue-angle-text-thumbnail.png"
                                                                                                                width="30" height="30" onClick={() => gerarXML(row.chave)}
                                                                                                            ></img>
                                                                                                        </a>
                                                                                                    </TableCell></Tooltip>
                                                                                                    <TableCell>Nfe</TableCell>
                                                                                                    <TableCell>{row.chave}</TableCell>

                                                                                                    <TableCell>{row.cnf}</TableCell>
                                                                                                    <TableCell>{row.dataemissao}</TableCell>
                                                                                                    <TableCell>{row.cnpjemitente}</TableCell>
                                                                                                    <TableCell>{row.nomeemitente}</TableCell>
                                                                                                    <TableCell>{row.nomedomucipioemitente}</TableCell>
                                                                                                    <TableCell>{row.cnpjdestinatario}</TableCell>
                                                                                                    <TableCell>{row.nomefantasiadestinatario}</TableCell>
                                                                                                    <TableCell>{row.valor}</TableCell>
                                                                                                </TableRow>

                                                                                            ))}


                                                                                        </TableBody>

                                                                                    </Table>

                                                                                </div>
                                                                            }
                                                                        </>

                                                                    )}
                                                                </div>
                                                                <div class="cardmov">
                                                                    <TablePagination
                                                                        colSpan={3}
                                                                        component="div"
                                                                        rowsPerPageOptions={[20]}
                                                                        count={quantidadenfecount}
                                                                        rowsPerPage={nfe.length}
                                                                        page={pagenumber}
                                                                        ActionsComponent={TablePaginationActions2}
                                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                                        labelDisplayedRows={({ from, to, count }) => (
                                                                            <>
                                                                                <p><strong>Total de notas do período:       {totalElements}</strong></p>
                                                                                <div class="custom-label">
                                                                                    <p><strong>Total de registros por página:        20</strong></p>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal
                            open={AlertaManifestacao}
                            onClose={handleClose}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                        >
                            <Box sx={{ ...style, width: 350 }}>
                                <img
                                    src={CloseButtonIcon}
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        right: '0',
                                        padding: '5px',
                                        cursor: 'pointer',
                                        maxWidth: '90%',
                                    }}
                                    width="50"
                                    onClick={handleClose}
                                    height="50" >
                                </img>

                                <div>
                                    <img
                                        src={WarningIcon}
                                        width="50"
                                        height="50"
                                    />
                                    <h2>
                                        Alerta: você selecionou Empresas diferentes para realizar a manifestação!
                                        Favor Manifestar uma única Empresa!
                                    </h2>
                                </div>
                            </Box>
                        </Modal>


                        <Modal
                            open={AlertaManifestacaoAux}
                            onClose={handleClose}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                        >
                            <Box sx={{ ...style, width: 800 }}>
                                <img
                                    src={CloseButtonIcon}
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        right: '0',
                                        padding: '5px',
                                        cursor: 'pointer',
                                        maxWidth: '90%',
                                    }}
                                    width="50"
                                    onClick={handleCloseAux}
                                    height="50" >
                                </img>

                                <div >
                                    <img
                                        src={WarningIcon}
                                        width="50"
                                        height="50"
                                    />
                                    <h4>Se essa mensagem aparecer contate o administrador do sistema ouve uma falha ou
                                        sistema nao conseguiu manifestacao automaticamente ou você pode realizar Manifestação Manualmente !!!
                                    </h4>
                                    <table className="tabelapersonalizada">
                                        <thead>
                                            <tr>
                                                <th>Tipo Manifestação </th>
                                                <th>Observação</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Ciencia da Operação</td>
                                                <td>10 Dias contados a partir da data de Autorização de Uso</td>

                                            </tr>

                                            <tr>
                                                <td>Confirmação da Operação</td>
                                                <td>180 Dias contador a partir da data de Autorização de NF-e</td>

                                            </tr>

                                            <tr>
                                                <td>Desconhecimento da Operação</td>
                                                <td> 180 Dias contador a partir da data de Autorização de NF-e</td>

                                            </tr>

                                            <tr>
                                                <td>Operação Não Realizada </td>
                                                <td>180 Dias contador a partir da data de Autorização de NF-e</td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </Box>
                        </Modal>


                        <Modal
                            open={alertConcluido}
                            onClose={handleCloseManifesto}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                        >
                            <Box sx={{ ...style, width: 500 }}>
                                <h2 ><img
                                    src={Success}
                                    width="30"
                                    height="30" >
                                </img> Nota Manifestada com Sucesso !</h2>
                            </Box>
                        </Modal>


                        <Modal
                            open={alertConcluidoAlerta}
                            onClose={handleCloseManifestoAlert}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                        >
                            <Dialog
                                open={true}
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <div className="pos-center">
                                    <Spinner />
                                </div>
                            </Dialog>
                        </Modal>


                        <Modal
                            open={alertWarning}
                            onClose={handleCloseWarning}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                        >
                            <Box sx={{ ...style, width: 400 }}>
                                <h2 > Essa nota ja foi manifestada consulte a nota da sefaz, se nao estiver manifestada entre em contato com departamento de ti <img src={InfoIcon}

                                    width="20" height="20" style={{ marginLeft: '1%' }}
                                ></img></h2>

                                <p></p>
                                <p >
                                    <button type="button" class="btn btn-block btn-danger btn-lg" onClick={handleCloseWarning}>Fechar</button>

                                </p>

                            </Box>
                        </Modal>

                    </div>
                    </div>
                </div >
            </div >
            <Footer />
        </div >

    )
}