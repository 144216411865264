import React from 'react';
import './custom.css';

export default props => (
  <footer className='main-footer'>
    <div className='footer-content'>
      <strong>
        PORTAL CLOUD GRUPO JCR - 2024 - Version: 1.04.01
        <a style={{ color: '#00F' }} href='https://grupojcr.com.br/'> GRUPO JCR SITE.  </a>
      </strong>
    </div>
  </footer>
);
